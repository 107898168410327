import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Icon } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import UserProfile from "./UserProfile";
//import AppsNavigation from "./AppsNavigation";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";

const SubMenu = Menu.SubMenu;

class SidebarContent extends Component {

  // submenu keys of first level
  rootSubmenuKeys = ['adminsettings', 'sub2', 'sub4'];

  state = {
    openKeys: ['adminsettings'],
    current: 'dashboard',
  };

  handleClick = e => {
    this.setState({
      current: e.key,
    });
  };

  onOpenChange = openKeys => {
    const latestOpenKey = openKeys.find(key => this.state.openKeys.indexOf(key) === -1);
    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys });
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : [],
      });
    }
  };

  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  render() {
    const { themeType, navStyle, authUser, businessLineDetails } = this.props;
    /* const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1]; */

    //Checking authUser
    let role_id = authUser ? authUser.role_id : 0;
    //console.log(authUser)

    return (
      <Auxiliary>
        <SidebarLogo />
        <div className="gx-sidebar-content">
          <div
            className={`gx-sidebar-notifications ${this.getNoHeaderClass(
              navStyle
            )}`}
          >
            <UserProfile />
            {/* <AppsNavigation/> This was a horizontal bar of 3 icons like bell etc. is removed */}
          </div>
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {/*********************************************************
             *      If role id is Farmer ; i.e., 20
             *********************************************************/
            role_id === 20 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="farmerdashboard">
                  <Link to="/va/farmerdashboard">
                    <Icon type="dashboard" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.dashboard" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="farmerIncentiveSimulator">
                  <Link to="/va/farmer-incentivesimulator">
                    <Icon type="percentage" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.incentiveSimulator" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="farmersummary">
                  <Link to="/va/farmer-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="farmermycontracts">
                    <Link to="/va/farmer-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="limboapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedfarmerlimbo">
                    <Link to="/va/farmer-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitingfarmerlimbo">
                    <Link to="/va/farmer-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="rejectedlimbo">
                      <Link to="/va/farmer-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="farmerlimbo">
                    <Link to="/va/farmer-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  {/* <Menu.Item key="farmerdetailedlimbo">
                    <Link to="/va/farmer-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}

                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="pricingsummary">
                      <Link to="/va/farmer-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/farmer-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitymanagement" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) 
            
            : /*********************************************************
             *      If role id is Technician; i.e., 22
             *********************************************************/
            role_id === 22 ? (
              
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="techniciandashboard">
                    <Link to="/va/techniciandashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="technicianIncentiveSimulator">
                    <Link to="/va/technician-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item> */}
                  <Menu.Item key="technicianusersummary">
                    <Link to="/va/technician-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedtechnicianlimbo">
                    <Link to="/va/technician-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitingtechnicianlimbo">
                    <Link to="/va/technician-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>

                  </SubMenu>

                  <Menu.Item key="technicianlimbo">
                    <Link to="/va/technician-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    {/* Profitability */}
                    {/* <Menu.Item key="underprofitabilitymanagement">
                      <Link to="/va/technician-underprofitabilitymanagement">
                        <Icon type="solution" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item> */}
                    {/* <Menu.Item key="pricingsummary">
                      <Link to="/va/technician-pricingsummary">
                        <Icon type="read" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.pricingSummary" />
                      </Link>
                    </Menu.Item> */}
                     <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/technician-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item> 
                  </SubMenu>
                  <Menu.Item key="technicianmycontracts">
                    <Link to="/va/technician-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
            )

            : /*********************************************************
             *      If role id is KAM ; i.e., 19
             *********************************************************/
            role_id === 19 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="kammanagerdashboard">
                  <Link to="/va/kammanagerdashboard">
                    <Icon type="dashboard" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.dashboard" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="kammanagerIncentiveSimulator">
                  <Link to="/va/kammanager-incentivesimulator">
                    <Icon type="percentage" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.incentiveSimulator" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="kammanagerUserSummary">
                  <Link to="kammanager-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="kammanagermycontracts">
                    <Link to="/va/kammanager-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="limboapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedkammanagerlimbo">
                    <Link to="/va/kammanager-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitingkammanagerlimbo">
                    <Link to="/va/kammanager-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="rejectedlimbo">
                      <Link to="/va/kammanager-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="kammanagerlimbo">
                    <Link to="/va/kammanager-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  {/* <Menu.Item key="kammanagerdetailedlimbo">
                    <Link to="/va/kammanager-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}

                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="pricingsummary">
                      <Link to="/va/kammanager-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/kammanager-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item> 
                  </SubMenu>

                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      If role id is Regional Businesss Controller ; i.e., 18
             *********************************************************/
            role_id === 18 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="regionalbusinesscontrollerusersummary">
                  <Link to="/va/regionalbusinesscontroller-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="regionalbusinesscontrollerlimbo">
                    <Link to="/va/regionalbusinesscontroller-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                    {/* profitability */}
                    <Menu.Item key="regionalbusinesscontrollerprofitability">
                    <Link to="/va/regionalbusinesscontroller-profitability">
                      <Icon type="solution" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.profitability" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="regionalbusinesscontrollerbillingblock">
                    <Link to="/va/regionalbusinesscontroller-billingblock">
                      <Icon type="money-collect" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.billingBlock" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="regionalbusinesscontrollermso">
                    <Link to="/va/regionalbusinesscontroller-mso">
                      <Icon type="schedule" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.mso" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="regionalbusinesscontrollerforecast">
                    <Link to="/va/regionalbusinesscontroller-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}
                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      If role id is Businesss Controller ; i.e., 17
             *********************************************************/
            role_id === 17 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="businesscontrollerusersummary">
                  <Link to="/va/businessusercontrollers-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="businessusercontrollerslimbo">
                    <Link to="/va/businessusercontrollers-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      If role id is kbs user ; i.e., 16
             *********************************************************/
            role_id === 16 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="kbsusersummary">
                  <Link to="/va/kbsuser-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                  {/* Limbo  */}
                  <SubMenu
                    key="limbo"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Limbo</span>
                      </span>
                    }
                  >
                    {/************ Limbo ************/}
                    <Menu.Item key="limbo">
                      <Link to="/va/kbsuser-limbo">
                        <Icon type="table" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.limboSummary" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="expiringin30">
                      <Link to="/va/kbsuser-limbo-expiring_0_to_30_days">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.expiringin30" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="limboexpired1to30">
                      <Link to="/va/kbsuser-limbo-expired_1_to_30_days">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.expired1to30" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="limboexpired31to90">
                      <Link to="/va/kbsuser-limbo-expired_31_to_90_days">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.expired31to90" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="limboexpired91to180">
                      <Link to="/va/kbsuser-limbo-expired_91_to_180_days">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.expired91to180" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="limboexpired180">
                      <Link to="/va/kbsuser-limbo-expired_180_days">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.expired180" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <SubMenu
                    key="kbsTransactionalDataUpload"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        Transactional Data Upload
                      </div>
                    }
                  >
                  <Menu.Item key="transactionalDataUpload">
                    <Link to="/va/kbsuser-lis-transactionaldataupload">
                      <Icon type="upload" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.lisTransactionalDataUpload" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="transactionalDataUpload">
                    <Link to="/va/kbsuser-lifs-transactionaldataupload">
                      <Icon type="upload" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.lifsTransactionalDataUpload" />
                    </Link>
                  </Menu.Item>
                  </SubMenu>

                <Menu.Item key="kbscontracthistoryy">
                  <Link to="/va/kbs-contracthistory">
                    <Icon type="history" style={{ fontSize: "16px" }} />
                    <IntlMessages id="sidebar.contractHistory" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="contractupload">
                  <Link to="/va/kbsuser-contractupload">
                    <Icon type="upload" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.contractupload" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      If role id is Hunter ; i.e., 15
             *********************************************************/
            role_id === 15 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="hunterdashboard">
                  <Link to="/va/hunterdashboard">
                    <Icon type="dashboard" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.dashboard" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="hunterIncentiveSimulator">
                  <Link to="/va/hunter-incentivesimulator">
                    <Icon type="percentage" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.incentiveSimulator" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="hunterUsersummary">
                  <Link to="/va/hunter-usersummary">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.usersummary" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="huntermycontracts">
                    <Link to="/va/hunter-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="limboapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedhunterlimbo">
                    <Link to="/va/hunter-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitinghunterlimbo">
                    <Link to="/va/hunter-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="rejectedlimbo">
                      <Link to="/va/hunter-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="hunterlimbo">
                    <Link to="/va/hunter-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  {/* <Menu.Item key="hunterdetailedlimbo">
                    <Link to="/va/hunter-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="pricingsummary">
                      <Link to="/va/hunter-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/hunter-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitymanagement" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      If role id is Specialist ; i.e., 14
             *********************************************************/
            role_id === 14 ? (
              <Menu
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                <Menu.Item key="specialistdashboard">
                  <Link to="/va/specialistdashboard">
                    <Icon type="dashboard" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.dashboard" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="specialistIncentiveSimulator">
                  <Link to="/va/specialist-incentivesimulator">
                    <Icon type="percentage" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.incentiveSimulator" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="specialistusersummary">
                    <Link to="/va/specialist-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="specialistmycontracts">
                    <Link to="/va/specialist-mycontracts">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="My Contracts" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="limboapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedspecialistlimbo">
                    <Link to="/va/specialist-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitingspecialistlimbo">
                    <Link to="/va/specialist-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="rejectedlimbo">
                      <Link to="/va/specialist-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="specialistlimbo">
                    <Link to="/va/specialist-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  {/* <Menu.Item key="specialistdetailedlimbo">
                    <Link to="/va/specialist-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="pricingsummary">
                      <Link to="/va/specialist-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/specialist-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                <Menu.Item key="tickets">
                  <Link to="/tickets">
                    <Icon
                      type="customer-service"
                      style={{ fontSize: "20px" }}
                    />
                    <IntlMessages id="sidebar.tickets" />
                  </Link>
                </Menu.Item>
              </Menu>
            ) : /*********************************************************
             *      else if role id is Sales Manager; i.e., 11
             *********************************************************/
            role_id === 11 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmanagerdashboard">
                    <Link to="/salesmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="salesmanagerlisttarget">
                    <Link to="/salesmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="like" style={{ fontSize: '20px' }} />Approvals</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmanagernsskickerpendingapprovals">
                      <Link to="/salesmanager-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>
                    {/* NSS Awaiting Approvals */}
                    <Menu.Item key="listnsskickerawaitingapprovals">
                      <Link to="/salesmanager-listnsskickerawaitingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>

                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="salesmanagernsskickerhistory">
                      <Link to="/salesmanager-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="salesmanagerCorrectionsList">
                          <Link to="/salesmanager-correctionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.corrections" /></Link>
                        </Menu.Item>

                        <Menu.Item key="salesmanagerCorrectionsHistory">
                          <Link to="/salesmanager-correctionshistory"><Icon type="history" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.correctionsHistory" /></Link>
                        </Menu.Item> */}
                    {/*-------------------------Over Achievement Exception------------------*/}
                    {/* <Menu.Item key="salesmanagerOverAchievementExceptions">
                          <Link to="/salesmanager-overachievementexception"><Icon type="exception" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.overAchievementException" /></Link>
                        </Menu.Item>
                        <Menu.Item key="salesmanagerOverAchievementExceptionsHistory">
                          <Link to="/salesmanager-overachievementexceptionhistory"><Icon type="history" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.overAchievementHistory" /></Link>
                        </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="rejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                      <span>
                        <i className="icon icon-menu" />
                        <span>Rejections </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmanagernsskickerrejected">
                      <Link to="/salesmanager-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="salesmanagerRejectedCorrectionsList">
                          <Link to="/salesmanager-rejectedcorrectionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.corrections" /></Link>
                        </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="salesmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="salesmanagerPendingPayouts">
                      <Link to="/salesmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="salesmanagerApprovedPayouts">
                      <Link to="/salesmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  <Menu.Item key="salesmanagerMysales">
                    <Link to="/salesmanager-mysales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="salesmanagerIncentiveSimulator">
                    <Link to="/salesmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmanagerdashboard">
                    <Link to="/va/salesmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>

                  {/************ Approvals ************/}
                  <SubMenu
                    key="approvals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/* Limbo Approvals */}
                    <Menu.Item key="pendinglimbo">
                      <Link to="/va/salesmanager-pendinglimbo">
                        <Icon type="table" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.pendinglimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="approvedlimbo">
                      <Link to="/va/salesmanager-approvedlimboapprovals">
                        <Icon type="check-square" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.approvedlimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="awaitinglimbo">
                      <Link to="/va/salesmanager-awaitinglimboapprovals">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.awaitinglimbo" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="rejectedlimbo">
                      <Link to="/va/salesmanager-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                    {/* Exception 
                      <Menu.Item key="salesmanagerException">
                      <Link to="/va/salesmanager-exception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.exception" />
                      </Link>
                    </Menu.Item> */}

                    {/* Pending Payouts */}
                    <Menu.Item key="salesmanagerPendingPayouts">
                      <Link to="/va/salesmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="salesmanagerApprovedPayouts">
                      <Link to="/va/salesmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="salesmanagerusersummary">
                    <Link to="/va/salesmanager-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    {/* Profitability */}
                     {/* <Menu.Item key="profitabilitysummary">
                      <Link to="/va/salesmanager-profitability-summary">
                        <Icon type="read" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitysummary" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitydetailedsummary">
                      <Link to="/va/salesmanager-profitability">
                        <Icon type="reconciliation" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitydetailedsummary" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitynew">
                      <Link to="/va/salesmanager-profitability-new">
                        <Icon type="plus-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.new" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilityunderobservationperiod">
                      <Link to="/va/salesmanager-profitability-underobservationperiod">
                        <Icon type="monitor" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underobservationperiod" />
                      </Link>
                    </Menu.Item> 
                    <Menu.Item key="profitabilityexcludelist">
                      <Link to="/va/salesmanager-profitability-exclude">
                        <Icon type="schedule" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.excludelist" />
                      </Link>
                    </Menu.Item>   */}
                    <Menu.Item key="underpricingmanagement">
                      <Link to="/va/salesmanager-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>               
                    {/* <Menu.Item key="underprofitabilitymanagement">
                      <Link to="/va/salesmanager-profitability-underprofitabilitymanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item> */}
                    <Menu.Item key="profitabilitysummary">
                      <Link to="/va/salesmanager-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item>                      
                  </SubMenu>


                  {/* Target Settings */}
                  <Menu.Item key="salesmanagerlisttarget">
                    <Link to="/va/salesmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="salesmanagermycontracts">
                    <Link to="/va/salesmanager-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>

                  {/* Sales */}
                  {/* <SubMenu
                      key="sales"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      title={
                        // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                        <span><i className="icon icon-menu" /><span>Sales </span></span>
                      }
                    > */}
                  {/************ NSS Kicker ************/}
                  {/* <Menu.Item key="salesmanagersales">
                        <Link to="/va/salesmanager-sales"><Icon type="line-chart" style={{ fontSize: '20px' }} />
                          <IntlMessages id="sidebar.actualsales" /></Link>
                      </Menu.Item>

                    </SubMenu> */}

                  <Menu.Item key="salesmanagerIncentiveSimulator">
                    <Link to="/va/salesmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="salesmanagerlimbo">
                    <Link to="/va/salesmanager-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="salesmanagerdetailedlimbo">
                    <Link to="/va/salesmanager-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}
                  {/* <Menu.Item key="salesmanagerforecast">
                    <Link to="/va/salesmanager-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}
                  
                  {/* Reports */}
                  <SubMenu
                    key="salesmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="smCommissionDetailedReport">
                      <Link to="/va/salesmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="smcommissionsummaryreport">
                      <Link to="/va/salesmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) 
            


            : /*********************************************************
             *      else if role id is Operations Manager; i.e., 21
             *********************************************************/
            role_id === 21 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="operationsmanagerdashboard">
                    <Link to="/operationsmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="operationsmanagerlisttarget">
                    <Link to="/operationsmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="like" style={{ fontSize: '20px' }} />Approvals</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="operationsmanagernsskickerpendingapprovals">
                      <Link to="/operationsmanager-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.operationsmanagerlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>
                    {/* NSS Awaiting Approvals */}
                    <Menu.Item key="listnsskickerawaitingapprovals">
                      <Link to="/operationsmanager-listnsskickerawaitingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.operationsmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>

                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="operationsmanagernsskickerhistory">
                      <Link to="/operationsmanager-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="operationsmanagerCorrectionsList">
                          <Link to="/operationsmanager-correctionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.corrections" /></Link>
                        </Menu.Item>

                        <Menu.Item key="operationsmanagerCorrectionsHistory">
                          <Link to="/operationsmanager-correctionshistory"><Icon type="history" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.correctionsHistory" /></Link>
                        </Menu.Item> */}
                    {/*-------------------------Over Achievement Exception------------------*/}
                    {/* <Menu.Item key="operationsmanagerOverAchievementExceptions">
                          <Link to="/operationsmanager-overachievementexception"><Icon type="exception" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.overAchievementException" /></Link>
                        </Menu.Item>
                        <Menu.Item key="operationsmanagerOverAchievementExceptionsHistory">
                          <Link to="/operationsmanager-overachievementexceptionhistory"><Icon type="history" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.overAchievementHistory" /></Link>
                        </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="rejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                      <span>
                        <i className="icon icon-menu" />
                        <span>Rejections </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="operationsmanagernsskickerrejected">
                      <Link to="/operationsmanager-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.operationsmanagerlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="operationsmanagerRejectedCorrectionsList">
                          <Link to="/operationsmanager-rejectedcorrectionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                            <IntlMessages id="sidebar.corrections" /></Link>
                        </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="operationsmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="operationsmanagerPendingPayouts">
                      <Link to="/operationsmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="operationsmanagerApprovedPayouts">
                      <Link to="/operationsmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  <Menu.Item key="operationsmanagerMysales">
                    <Link to="/operationsmanager-mysales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="operationsmanagerIncentiveSimulator">
                    <Link to="/operationsmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  {/* <Menu.Item key="operationsmanagerdashboard">
                    <Link to="/va/operationsmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item> */}

                  {/************ Approvals ************/}
                  <SubMenu
                    key="approvals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/* Limbo Approvals */}
                    <Menu.Item key="pendinglimbo">
                      <Link to="/va/operationsmanager-pendinglimbo">
                        <Icon type="table" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.pendinglimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="approvedlimbo">
                      <Link to="/va/operationsmanager-approvedlimboapprovals">
                        <Icon type="check-square" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.approvedlimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="awaitinglimbo">
                      <Link to="/va/operationsmanager-awaitinglimboapprovals">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.awaitinglimbo" />
                      </Link>
                    </Menu.Item>
                    {/* Exception */}
                      <Menu.Item key="operationsmanagerException">
                      <Link to="/va/operationsmanager-exception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.exception" />
                      </Link>
                    </Menu.Item> 

                    {/* Pending Payouts */}
                    <Menu.Item key="operationsmanagerPendingPayouts">
                      <Link to="/va/operationsmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="operationsmanagerApprovedPayouts">
                      <Link to="/va/operationsmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="operationsmanagerusersummary">
                    <Link to="/va/operationsmanager-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    {/* <Menu.Item key="underprofitabilitymanagement">
                      <Link to="/va/operationsmanager-underprofitabilitymanagement">
                        <Icon type="solution" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item> */}
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/operationsmanager-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitymanagement" />
                      </Link>
                    </Menu.Item> 
                  </SubMenu>

                  {/* Target Settings */}
                  {/* <Menu.Item key="operationsmanagerlisttarget">
                    <Link to="/va/operationsmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item> */}

                  <Menu.Item key="operationsmanagermycontracts">
                    <Link to="/va/operationsmanager-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>

                  {/* Sales */}
                  {/* <SubMenu
                      key="sales"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      title={
                        // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                        <span><i className="icon icon-menu" /><span>Sales </span></span>
                      }
                    > */}
                  {/************ NSS Kicker ************/}
                  {/* <Menu.Item key="operationsmanagersales">
                        <Link to="/va/operationsmanager-sales"><Icon type="line-chart" style={{ fontSize: '20px' }} />
                          <IntlMessages id="sidebar.actualsales" /></Link>
                      </Menu.Item>

                    </SubMenu> */}

                  {/* <Menu.Item key="operationsmanagerIncentiveSimulator">
                    <Link to="/va/operationsmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item> */}

                  <Menu.Item key="operationsmanagerlimbo">
                    <Link to="/va/operationsmanager-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="operationsmanagerforecast">
                    <Link to="/va/operationsmanager-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}
                  
                  {/* Reports */}
                  {/* <SubMenu
                    key="operationsmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    // Commission Detailed Reports 
                    <Menu.Item key="smCommissionDetailedReport">
                      <Link to="/va/operationsmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    // Commission Summary  Reports
                    <Menu.Item key="smcommissionsummaryreport">
                      <Link to="/va/operationsmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu> */}
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) 
            
            
            : /*********************************************************
             *      If role id is Salesman / sales person; i.e., 12
             *********************************************************/
            role_id === 12 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmandashboard">
                    <Link to="/salesmandashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="salesmanMysales">
                    <Link to="/salesman-mysales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  {/**************************************************************************************************/}
                  {/*Submenu for Pending Approvals */}
                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmannsskickerpendingapprovals">
                      <Link to="/salesman-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>

                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="salesmannsskickerhistory">
                      <Link to="/salesman-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Change Commission ************/}
                    {/* <Menu.Item key="regionalmanagerChangeCommissionList">
                            <Link to="/regionalmanager-changecommissionlist"><Icon type="money-collect" style={{ fontSize: '16px' }} />
                              <IntlMessages id="sidebar.commissionList" /></Link>
                          </Menu.Item> */}

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="regionalmanagerCorrectionsList">
                            <Link to="/regionalmanager-correctionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                              <IntlMessages id="sidebar.corrections" /></Link>
                          </Menu.Item> */}

                    {/************ Correcitions History ************/}
                    {/* <Menu.Item key="regionalmanagerCorrectionsHistory">
                            <Link to="/regionalmanager-correctionshistory"><Icon type="history" style={{ fontSize: '16px' }} />
                              <IntlMessages id="sidebar.correctionsHistory" /></Link>
                          </Menu.Item> */}
                    {/************ Over Achievement Exceptions ************/}
                    {/* <Menu.Item key="regionalmanagerOverAchievementExceptions">
                            <Link to="/regionalmanager-overachievementexception"><Icon type="exception" style={{ fontSize: '16px' }} />
                              <IntlMessages id="sidebar.overAchievementException" /></Link>
                          </Menu.Item> */}
                    {/* <Menu.Item key="regionalmanagerOverAchievementExceptionsHistory">
                            <Link to="/regionalmanager-overachievementexceptionhistory"><Icon type="history" style={{ fontSize: '16px' }} />
                              <IntlMessages id="sidebar.overAchievementHistory" /></Link>
                          </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/**************************************************************************************************/}
                  {/*Submenu for Rejected Sales from Approval*/}
                  <SubMenu
                    key="rejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Rejections </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmannsskickerrejected">
                      <Link to="/salesman-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  {/**************************************************************************************************/}
                  <Menu.Item key="salesmanIncentiveSimulator">
                    <Link to="/salesman-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanIncentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmandashboard">
                    <Link to="/va/salesmandashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="salesmanagerIncentiveSimulator">
                    <Link to="/va/salesman-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="salesmanusersummary">
                    <Link to="/va/salesman-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                  <Menu.Item key="approvedsalesmanlimbo">
                    <Link to="/va/salesman-approvedlimboapprovals">
                      <Icon type="check-square" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.approvedlimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="awaitingsalesmanlimbo">
                    <Link to="/va/salesman-awaitinglimboapprovals">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.awaitinglimbo" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="rejectedlimbo">
                      <Link to="/va/salesman-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>

                  </SubMenu>

                  <Menu.Item key="salesmanlimbo">
                    <Link to="/va/salesman-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  {/* <Menu.Item key="salesmandetailedlimbo">
                    <Link to="/va/salesman-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}

                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="pricingsummary">
                      <Link to="/va/salesman-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/salesman-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="salesmanmycontracts">
                    <Link to="/va/salesman-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*********************************************************
             *      else if role id is Sales Manager; i.e., 11
             *********************************************************/
            role_id === 11 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmanagerdashboard">
                    <Link to="/salesmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="salesmanagerlisttarget">
                    <Link to="/salesmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="pendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="like" style={{ fontSize: '20px' }} />Approvals</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmanagernsskickerpendingapprovals">
                      <Link to="/salesmanager-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>
                    {/* NSS Awaiting Approvals */}
                    <Menu.Item key="listnsskickerawaitingapprovals">
                      <Link to="/salesmanager-listnsskickerawaitingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>

                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="salesmanagernsskickerhistory">
                      <Link to="/salesmanager-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    <Menu.Item key="salesmanagerCorrectionsList">
                      <Link to="/salesmanager-correctionslist">
                        <Icon type="highlight" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.corrections" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions History ************/}
                    <Menu.Item key="salesmanagerCorrectionsHistory">
                      <Link to="/salesmanager-correctionshistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionsHistory" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="rejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                      <span>
                        <i className="icon icon-menu" />
                        <span>Rejections </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmanagernsskickerrejected">
                      <Link to="/salesmanager-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    <Menu.Item key="salesmanagerRejectedCorrectionsList">
                      <Link to="/salesmanager-rejectedcorrectionslist">
                        <Icon type="highlight" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.corrections" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="salesmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="salesmanagerPendingPayouts">
                      <Link to="/salesmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="salesmanagerApprovedPayouts">
                      <Link to="/salesmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  <Menu.Item key="salesmanagerMysales">
                    <Link to="/salesmanager-mysales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanagerMysales" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="salesmanagerIncentiveSimulator">
                    <Link to="/salesmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="salesmanagerdashboard">
                    <Link to="/va/salesmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>

                  {/* Target Settings */}
                  <Menu.Item key="salesmanagerlisttarget">
                    <Link to="/va/salesmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>

                  {/* Sales */}
                  <SubMenu
                    key="sales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="dislike" style={{ fontSize: '20px' }} />Rejections</div>/
                      <span>
                        <i className="icon icon-menu" />
                        <span>Sales </span>
                      </span>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="salesmanagersales">
                      <Link to="/va/salesmanager-sales">
                        <Icon type="line-chart" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.actualsales" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="salesmanagermycontracts">
                    <Link to="/va/salesmanager-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="salesmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="salesmanagerPendingPayouts">
                      <Link to="/va/salesmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="salesmanagerApprovedPayouts">
                      <Link to="/va/salesmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="salesmanagerIncentiveSimulator">
                    <Link to="/va/salesmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  {/* Reports */}
                  <SubMenu
                    key="salesmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="smCommissionDetailedReport">
                      <Link to="/va/salesmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="smcommissionsummaryreport">
                      <Link to="/va/salesmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*********************************************************
             *      else if role id is Regional Manager; i.e., 10
             *********************************************************/
            role_id === 10 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="regionalmanagerdashboard">
                    <Link to="/regionalmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="regionalmanagerlisttarget">
                    <Link to="/regionalmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="pendingapprovalsregionalmanager"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="regionalmanagernsskickerpendingapprovals">
                      <Link to="/regionalmanager-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.regionalmanagerlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>
                    {/* NSS Awaiting Approvals */}
                    <Menu.Item key="listnsskickerawaitingapprovals">
                      <Link to="/regionalmanager-listnsskickerawaitingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>
                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="regionalmanagernsskickerhistory">
                      <Link to="/regionalmanager-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Change Commission ************/}
                    {/* <Menu.Item key="regionalmanagerChangeCommissionList">
                                <Link to="/regionalmanager-changecommissionlist"><Icon type="money-collect" style={{ fontSize: '16px' }} />
                                  <IntlMessages id="sidebar.commissionList" /></Link>
                              </Menu.Item> */}

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="regionalmanagerCorrectionsList">
                                <Link to="/regionalmanager-correctionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                                  <IntlMessages id="sidebar.corrections" /></Link>
                              </Menu.Item> */}

                    {/************ Correcitions History ************/}
                    {/* <Menu.Item key="regionalmanagerCorrectionsHistory">
                                <Link to="/regionalmanager-correctionshistory"><Icon type="history" style={{ fontSize: '16px' }} />
                                  <IntlMessages id="sidebar.correctionsHistory" /></Link>
                              </Menu.Item> */}
                    {/* <Menu.Item key="mdawatingcommisssionexception">
                                <Link to="/md-awaitingcommisssionexceptions"><Icon type="control" style={{ fontSize: '16px' }} />
                                  <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" /></Link>
                              </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="rejectedsalesregionalmanager"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="dislike" style={{ fontSize: "20px" }} />
                        Rejections
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="regionalmanagernsskickerrejected">
                      <Link to="/regionalmanager-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.regionalmanagerlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="regionalmanagerRejectedCorrectionsList">
                                <Link to="/regionalmanager-rejectedcorrectionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                                  <IntlMessages id="sidebar.corrections" /></Link>
                              </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="regionalmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="regionalmanagerPendingPayouts">
                      <Link to="/regionalmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="regionalmanagerApprovedPayouts">
                      <Link to="/regionalmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  <Menu.Item key="regionalmanagerMysales">
                    <Link to="/regionalmanager-mysales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="regionalmanagerIncentiveSimulator">
                    <Link to="/regionalmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="regionalmanagerdashboard">
                    <Link to="/va/regionalmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="regionalmanagerapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Approvals </span>
                      </span>
                    }
                  >
                    {/************ Limbo Approvals ************/}
                    <Menu.Item key="pendinglimbo">
                      <Link to="/va/regionalmanager-pendinglimboapprovals">
                        <Icon type="table" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.pendinglimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="approvedlimbo">
                      <Link to="/va/regionalmanager-approvedlimboapprovals">
                        <Icon type="check-square" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.approvedlimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="awaitinglimbo">
                      <Link to="/va/regionalmanager-awaitinglimboapprovals">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.awaitinglimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="rejectedlimbo">
                      <Link to="/va/regionalmanager-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                    {/* Pending Payouts */}
                    <Menu.Item key="regionalmanagerPendingPayouts">
                      <Link to="/va/regionalmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="regionalmanagerApprovedPayouts">
                      <Link to="/va/regionalmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>

                  {/* user summery */}
                  <Menu.Item key="regionalmanagerusersummary">
                    <Link to="/va/regionalmanager-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>
                  {/* Profitability */}
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Pricing & Profitability</span>
                      </span>
                    }
                  >
                    <Menu.Item key="underpricingmanagement">
                      <Link to="/va/regionalmanager-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item> 
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/regionalmanager-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item>              
                  </SubMenu>
                  <Menu.Item key="regionalmanagermycontracts">
                    <Link to="/va/regionalmanager-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>

                  {/* Target Settings */}
                  <Menu.Item key="regionalmanagerlisttarget">
                    <Link to="/va/regionalmanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/* Sales */}
                  <Menu.Item key="salesmanagersales">
                    <Link to="/va/regionalmanager-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.actualsales" />
                    </Link>
                  </Menu.Item>
                  {/* Payout Ends */}
                  <Menu.Item key="regionalmanagerIncentiveSimulator">
                    <Link to="/va/regionalmanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="regionalmanagerlimbo">
                    <Link to="/va/regionalmanager-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="regionalmanagerdetailedlimbo">
                    <Link to="/va/regionalmanager-detailedlimbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}

                  {/* <Menu.Item key="regionalmanagerforecast">
                    <Link to="/va/regionalmanager-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}

                  {/* Reports */}
                  <SubMenu
                    key="regionalmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="rmCommissionDetailedReport">
                      <Link to="/va/regionalmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="rmcommissionsummaryreport">
                      <Link to="/va/regionalmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*********************************************************
             *      else if role id is HR Manager; i.e., 9
             *********************************************************/
            role_id === 9 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="hrmanagerdashboard">
                    <Link to="/hrmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="hrmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="interaction" style={{ fontSize: '20px' }} />Payouts</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Payouts</span>
                      </span>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="hrmanagerPendingPayouts">
                      <Link to="/hrmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="hrmanagerApprovedPayouts">
                      <Link to="/hrmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* Payout Ends */}
                  {/* Report Submenu */}
                  {/* <SubMenu
                                key="hrmanagerreport"
                                className={this.getNavStyleSubMenuClass(navStyle)}
                                title={
                                  <span><i className="icon icon-menu" /><span>Reports </span></span>
                                }
                              >
                                <Menu.Item key="hrmanagerreportCommissionReport">
                                  <Link to="/hrmanager-changecommissionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                    <IntlMessages id="sidebar.commissionReport" /></Link>
                                </Menu.Item>

                                <Menu.Item key="hrmanagerreportCorrectionreport">
                                  <Link to="/hrmanager-correctionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                    <IntlMessages id="sidebar.correctionReport" /></Link>
                                </Menu.Item>
                                <Menu.Item key="hrmanagerreportTargetDistributorReport">
                                  <Link to="/hrmanager-targetdistributorreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                    <IntlMessages id="sidebar.targetDistributorReport" /></Link>
                                </Menu.Item>
                                <Menu.Item key="hrmanagerreportPayoutStatusReport">
                                  <Link to="/hrmanager-payoutstatusreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                    <IntlMessages id="sidebar.payoutStatusReport" /></Link>
                                </Menu.Item>
                                <Menu.Item key="hrmanagerPayoutReport">
                                  <Link to="/hrmanager-payoutreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                    <IntlMessages id="sidebar.payoutReport" /></Link>
                                </Menu.Item>
                              </SubMenu> */}
                  <Menu.Item key="hrmanagerSales">
                    <Link to="/hrmanager-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="hrmanagerdashboard">
                    <Link to="/va/hrmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="hrmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="interaction" style={{ fontSize: '20px' }} />Payouts</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Payouts</span>
                      </span>
                    }
                  >
                    <Menu.Item key="hrmanagerPendingPayouts">
                      <Link to="/va/hrmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>
                    {/* Approved Payouts */}
                    <Menu.Item key="hrmanagerApprovedPayouts">
                      <Link to="/va/hrmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* Reports */}
                  <SubMenu
                    key="hrmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="hrmanagerCommissionDetailedReport">
                      <Link to="/va/hrmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="hrmanagercommissionsummaryreport">
                      <Link to="/va/hrmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="hrmanagerreportTargetDistributorReport">
                      <Link to="/va/hrmanager-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="hrmanagerreportPayoutStatusReport">
                      <Link to="/va/hrmanager-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Report************/}
                    <Menu.Item key="hrmanagerPayoutReport">
                      <Link to="/va/hrmanager-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*********************************************************
             *      else if role id is MD; i.e., 8
             *********************************************************/
            role_id === 8 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="mddashboard">
                    <Link to="/mddashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="mdlisttarget">
                    <Link to="/md-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="pendingapprovalsmd"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="mdnsskickerpendingapprovals">
                      <Link to="/md-listnsskickerpendingapprovals">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.mdlistnsskickerpendingapprovals" />
                      </Link>
                    </Menu.Item>

                    {/************ NSS Kicker History ************/}
                    <Menu.Item key="mdnsskickerhistory">
                      <Link to="/md-listnsskickerhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nssKickerHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Over Achievement Exception ************/}
                    <Menu.Item key="mdoverachievementexception">
                      <Link to="/md-overachievementexception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementException" />
                      </Link>
                    </Menu.Item>

                    {/************ Over Achievement Exception History************/}
                    <Menu.Item key="mdoverachievementexceptionhistory">
                      <Link to="/md-overachievementexceptionhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementHistory" />
                      </Link>
                    </Menu.Item>

                    {/************ Change Commission ************/}
                    {/* <Menu.Item key="mdChangeCommissionList">
                                    <Link to="/md-changecommissionlist"><Icon type="money-collect" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.commissionList" /></Link>
                                  </Menu.Item> */}

                    {/************ Commission History ************/}
                    {/* <Menu.Item key="mdCommissionListHistory">
                                    <Link to="/md-commissionlisthistory"><Icon type="history" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.commissionListHistory" /></Link>
                                  </Menu.Item> */}

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="mdCorrectionsList">
                                    <Link to="/md-correctionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.corrections" /></Link>
                                  </Menu.Item> */}

                    {/************ Correcitions History ************/}
                    {/* <Menu.Item key="mdCorrectionsHistory">
                                    <Link to="/md-correctionshistory"><Icon type="history" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.correctionsHistory" /></Link>
                                  </Menu.Item> */}
                    {/* <Menu.Item key="mdawatingcommisssionexception">
                                    <Link to="/md-awaitingcommisssionexceptions"><Icon type="control" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" /></Link>
                                  </Menu.Item> */}
                    {/************ Over Achievement Exceptions ************/}
                    {/* <Menu.Item key="mdOverAchievementExceptions">
                                    <Link to="/md-overachievementexception"><Icon type="exception" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.overAchievementException" /></Link>
                                  </Menu.Item>
                                  <Menu.Item key="mdOverAchievementExceptionsHistory">
                                    <Link to="/md-overachievementexceptionhistory"><Icon type="history" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.overAchievementHistory" /></Link>
                                  </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="rejectedsalesmd"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="dislike" style={{ fontSize: "20px" }} />
                        Rejections
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    <Menu.Item key="mdnsskickerrejected">
                      <Link to="/md-listnsskickerrejected">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.mdlistnsskickerrejected" />
                      </Link>
                    </Menu.Item>

                    {/************ Change Commission ************/}
                    {/* <Menu.Item key="mdRejectedCommissionList">
                                    <Link to="/md-rejectedcommissionlist"><Icon type="money-collect" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.commissionList" /></Link>
                                  </Menu.Item> */}

                    {/************ Correcitions ************/}
                    {/* <Menu.Item key="mdRejectedCorrectionsList">
                                    <Link to="/md-rejectedcorrectionslist"><Icon type="highlight" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.corrections" /></Link>
                                  </Menu.Item> */}

                    {/************ Exception sales ************/}
                    {/* <Menu.Item key="mdExceptionsalesList">
                                    <Link to="/md-exceptionsale"><Icon type="exclamation-circle" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.mdCVExceptionSales" /></Link>
                                  </Menu.Item> */}
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="mdpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="mdPendingPayouts">
                      <Link to="/md-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="mdApprovedPayouts">
                      <Link to="/md-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  <Menu.Item key="mdSales">
                    <Link to="/md-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="mdCVExceptionSales">
                                  <Link to="/md-cvexceptionsales"><Icon type="exclamation-circle" style={{ fontSize: '20px' }} />
                                    <IntlMessages id="sidebar.mdCVExceptionSales" /></Link>
                                </Menu.Item> */}
                  {/* <Menu.Item key="mdIncentiveSimulator">
                                  <Link to="/md-incentivesimulator"><Icon type="percentage" style={{ fontSize: '20px' }} />
                                    <IntlMessages id="sidebar.incentiveSimulator" /></Link>
                                </Menu.Item> */}
                  {/* Report Submenu */}
                  {/* <SubMenu
                                  key="mdreport"
                                  className={this.getNavStyleSubMenuClass(navStyle)}
                                  title={
                                    <div><Icon type="profile" style={{ fontSize: '20px' }} />Report</div>
                                  }
                                >
                                  <Menu.Item key="mdreportCommissionReport">
                                    <Link to="/md-changecommissionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.commissionReport" /></Link>
                                  </Menu.Item>

                                  <Menu.Item key="mdreportCorrectionreport">
                                    <Link to="/md-correctionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.correctionReport" /></Link>
                                  </Menu.Item>
                                  <Menu.Item key="mdreportTargetDistributorReport">
                                    <Link to="/md-targetdistributorreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.targetDistributorReport" /></Link>
                                  </Menu.Item>
                                  <Menu.Item key="mdreportPayoutStatusReport">
                                    <Link to="/md-payoutstatusreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.payoutStatusReport" /></Link>
                                  </Menu.Item>
                                  <Menu.Item key="mdPayoutReport">
                                    <Link to="/md-payoutreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                      <IntlMessages id="sidebar.payoutReport" /></Link>
                                  </Menu.Item>
                                </SubMenu> */}
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="mddashboard">
                    <Link to="/va/mddashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>

                  {/* Target Settings */}
                  <Menu.Item key="mdlisttarget">
                    <Link to="/va/md-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="mdmycontracts">
                    <Link to="/va/md-mycontracts">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.myContracts" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="mdusersummary">
                    <Link to="/va/md-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>  
                  <SubMenu
                    key="profitability"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        Pricing & Profitability
                      </div>
                    }
                  >
                    {/* Profitability */}
                    {/* <Menu.Item key="profitabilitysummary">
                      <Link to="/va/salesmanager-profitability-summary">
                        <Icon type="read" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitysummary" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitydetailedsummary">
                      <Link to="/va/salesmanager-profitability">
                        <Icon type="reconciliation" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitydetailedsummary" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilitynew">
                      <Link to="/va/salesmanager-profitability-new">
                        <Icon type="plus-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.new" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="profitabilityunderobservationperiod">
                      <Link to="/va/salesmanager-profitability-underobservationperiod">
                        <Icon type="monitor" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underobservationperiod" />
                      </Link>
                    </Menu.Item>  */}
                    <Menu.Item key="underpricingmanagement">
                      <Link to="/va/md-pricingmanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underpricingmanagement" />
                      </Link>
                    </Menu.Item>  
                    <Menu.Item key="profitabilitymanagement">
                      <Link to="/va/md-profitabilitymanagement">
                        <Icon type="file-text" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.profitabilitymanagement" />
                      </Link>
                    </Menu.Item>              
                    {/* <Menu.Item key="underprofitabilitymanagement">
                      <Link to="/va/md-profitability-underprofitabilitymanagement">
                        <Icon type="file-search" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.underprofitabilitymanagement" />
                      </Link>
                    </Menu.Item>     */}
                  {/* <Menu.Item key="profitabilityexcludelist">
                      <Link to="/va/md-profitability-exclude">
                        <Icon type="schedule" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.excludelist" />
                      </Link>
                    </Menu.Item> */}
                  
                  </SubMenu>               
                  <SubMenu
                    key="pendingapprovalsmd"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/* Limbo Approvals */}
                    <Menu.Item key="pendinglimboapprovals">
                      <Link to="/va/md-pendinglimboapprovals">
                        <Icon type="table" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.pendinglimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="approvedlimboapprovals">
                      <Link to="/va/md-approvedlimboapprovals">
                        <Icon type="check-square" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.approvedlimbo" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="rejectedlimbo">
                      <Link to="/va/md-rejectedlimbo">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.rejectedlimbo" />
                      </Link>
                    </Menu.Item>
                    {/* Pending Payouts */}
                    <Menu.Item key="mdPendingPayouts">
                      <Link to="/va/md-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="mdApprovedPayouts">
                      <Link to="/va/md-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                    {/************ Over Achievement Exceptions ************/}
                    <Menu.Item key="mdOverAchievementExceptions">
                      <Link to="/va/md-overachievementexception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementException" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="mdOverAchievementExceptionsHistory">
                      <Link to="/va/md-overachievementexceptionhistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementHistory" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="mdTargetApproval">
                      <Link to="/va/md-targetapproval">
                        <Icon type="setting" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.target" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* <SubMenu
                    key="mdexceptions"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="exception" style={{ fontSize: "20px" }} />
                        Exception
                      </div>
                    }
                  > */}
                    {/* Pending Payouts */}
                    {/* <Menu.Item key="mdchangecommission">
                      <Link to="/va/md-changecommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.payoutexception" />
                      </Link>
                    </Menu.Item> */}

                    {/* Approved Payouts */}
                    {/* <Menu.Item key="mdcollectioncommission">
                      <Link to="/va/md-collectioncommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.tr120Incentive" />
                      </Link>
                    </Menu.Item>
                  </SubMenu> */}
                  {/* Reports */}

                  <Menu.Item key="mdlimbo">
                    <Link to="/va/md-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                  {/* <Menu.Item key="mddetailedlimbo">
                    <Link to="/va/md-limbo">
                      <Icon type="hdd" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.detailedlimbosummary" />
                    </Link>
                  </Menu.Item> */}

                  {/* <Menu.Item key="mdforecast">
                    <Link to="/va/md-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}

                  <SubMenu
                    key="mdCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="mdCommissionDetailedReport">
                      <Link to="/va/md-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="mdcommissionsummaryreport">
                      <Link to="/va/md-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="mdreportTargetDistributorReport">
                      <Link to="/va/md-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="mdreportPayoutStatusReport">
                      <Link to="/va/md-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Report************/}
                    <Menu.Item key="mdPayoutReport">
                      <Link to="/va/md-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /****************************************************
             *      else if role id is SDM; i.e., 7
             ****************************************************/
            role_id === 7 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="sdmdashboard">
                    <Link to="/sdmdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Submenu for Geographical Level */}
                  <SubMenu
                    key="geographicallevel"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    style={{ fontSize: "20px" }}
                    title={
                      <span>
                        <i className="icon icon-select" />
                        <span>Geographical Level</span>
                      </span>
                    }
                  >
                    <Menu.Item key="managearea">
                      <Link to="/listareas">
                        <Icon type="global" />
                        <IntlMessages id="sidebar.managearea" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="managefrontline">
                      <Link to="/listfrontlines">
                        <Icon type="apartment" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.managefrontline" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="manageregion">
                      <Link to="/listregions">
                        <Icon type="appstore" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.manageregion" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="managebranches">
                      <Link to="/listbranches">
                        <Icon
                          type="deployment-unit"
                          style={{ fontSize: "14px" }}
                        />
                        <IntlMessages id="sidebar.managebranches" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Geographical Level Ends */}
                  <Menu.Item key="nsskicker">
                    {" "}
                    {/* NSS Kicker */}
                    <Link to="/nsskicker">
                      <Icon type="control" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.nsskicker" />
                    </Link>
                  </Menu.Item>
                  {/* Payout Settings */}
                  <Menu.Item key="sdmpayoutsettings">
                    <Link to="/listpayout">
                      <Icon type="build" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listpayout" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="users">
                    <Link to="/sdmusers">
                      <Icon type="team" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.users" />
                    </Link>
                  </Menu.Item>
                  {/* Report Submenu */}
                  <SubMenu
                    key="sdmreport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    <Menu.Item key="sdmCommissionReport">
                      <Link to="/sdm-changecommissionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Correction Report************/}
                    <Menu.Item key="sdmCorrectionreport">
                      <Link to="/sdm-correctionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="sdmTargetDistributorReport">
                      <Link to="/sdm-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Status Report************/}
                    <Menu.Item key="sdmPayoutStatusReport">
                      <Link to="/sdm-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="sdmPayoutReport">
                      <Link to="/sdm-report">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* INCENTIVES SETTINGS */}
                  <Menu.Item key="sdmincentivessettings">
                    <Link to="/listincentives">
                      <Icon type="build" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listincentives" />
                    </Link>
                  </Menu.Item>
                  {/* Sales */}
                  <SubMenu
                    key="sdmSales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="line-chart" style={{ fontSize: "20px" }} />
                        Sales
                      </div>
                    }
                  >
                    <Menu.Item key="sdmSales">
                      <Link to="/sdm-sales">
                        <Icon type="line-chart" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.actualsales" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="sdmException">
                      <Link to="/sdm-exception">
                        <Icon type="line-chart" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.exception" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="qlicksalesmenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Qlick Sense Sales</span>
                      </span>
                    }
                  >
                    <Menu.Item key="qlicksensesales">
                      <Link to="/sdm-qlicksensesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksensesalesready" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="qlicksenseduplicatesales">
                      <Link to="/sdm-qlicksenseduplicatesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksenseduplicatesales" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="qlicksenseexception">
                      <Link to="/sdm-qlicksenseexception">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksenseexception" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* DATA ANALYTICS */}]{" "}
                  <Menu.Item key="dataanalytics">
                    <Link to="/sdm-dataanalytics">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dataanalytics" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="sdmdashboard">
                    <Link to="/va/sdmdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sdmsummary">
                    <Link to="/va/sdm-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sdmlimbo">
                    <Link to="/va/sdm-limbo">
                      <Icon type="table" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.limbo" />
                    </Link>
                  </Menu.Item>
                    {/* profitability */}
                    <Menu.Item key="sdmprofitability">
                    <Link to="/va/sdm-profitability">
                      <Icon type="solution" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.profitability" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sdmbillingblock">
                    <Link to="/va/sdm-billingblock">
                      <Icon type="money-collect" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.billingBlock" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sdmmso">
                    <Link to="/va/sdm-mso">
                      <Icon type="schedule" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.mso" />
                    </Link>
                  </Menu.Item>

                  {/*<SubMenu
                    key="financialdata"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon
                          type="account-book"
                          style={{ fontSize: "20px" }}
                        />
                        Financial Data
                      </div>
                    }
                  >*/}
                    {/************ Financial Data ************/}
                    {/*<Menu.Item key="upload">
                      <Link to="/financialupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.dataEntry.upload" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="list">
                      <Link to="/financiallist">
                        <Icon type="profile" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.dataDisplay.list" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>*/}
                  <Menu.Item key="users">
                    <Link to="/va/sdmusers">
                      <Icon type="team" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.users" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="incentiveprocessing">
                    <Link to="/va/sdmincentive">
                      <Icon type="file" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveprocessing" />
                    </Link>
                  </Menu.Item>

                  <SubMenu
                    key="sdmTransactionalDataUpload"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        Transactional Data Upload
                      </div>
                    }
                  >
                  <Menu.Item key="transactionalDataUpload">
                    <Link to="/va/sdm-lis-transactionaldataupload">
                      <Icon type="upload" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.lisTransactionalDataUpload" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="transactionalDataUpload">
                    <Link to="/va/sdm-lifs-transactionaldataupload">
                      <Icon type="upload" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.lifsTransactionalDataUpload" />
                    </Link>
                  </Menu.Item>
                  </SubMenu>

                  <Menu.Item key="contractupload">
                    <Link to="/va/sdm-contractupload">
                      <Icon type="upload" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.contractupload" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="sdmcontracthistory">
                    <Link to="/va/sdm-contracthistory">
                      <Icon type="history" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.contractHistory" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="sdmforecast">
                    <Link to= {localStorage.getItem('isKmta') === 'false' ? "/va/sdm-forecast" 
                      : localStorage.getItem('isKmta') === 'true' ? "/va/sdm-forecast-kmta"
                      :"/va/sdm-forecast" 
                    }>
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item>

                  {/* Reports */}
                  <SubMenu
                    key="sdmCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="sdmCommissionDetailedReport">
                      <Link to="/va/sdm-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="sdmcommissionsummaryreport">
                      <Link to="/va/sdm-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="sdmTargetDistributorReport">
                      <Link to="/va/sdm-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Status Report************/}
                    <Menu.Item key="sdmPayoutStatusReport">
                      <Link to="/va/sdm-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="sdmPayoutReport">
                      <Link to="/va/sdm-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*************************************************************
             *      else if role id is Area Business Line Manager; i.e., 6
             **************************************************************/
            role_id === 6 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areabusinesslinemanagerdashboard">
                    <Link to="/areabusinesslinemanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="areabusinesslinemanagerlisttarget">
                    <Link to="/areabusinesslinemanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                 Submenu for Pending Approvals 
              *******************************/}
                  <SubMenu
                    key="areabusinesslinemanagerpendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    {/* <Menu.Item key="mdnsskickerpendingapprovals">    
                  <Link to="/md-listnsskickerpendingapprovals"><Icon type="control" style={{fontSize:'16px'}} />
                    <IntlMessages id="sidebar.mdlistnsskickerpendingapprovals"/></Link>
                </Menu.Item> */}

                    {/************ Change Commission ************/}
                    <Menu.Item key="areabusinesslinemanagerChangeCommissionList">
                      <Link to="/areabusinesslinemanager-changecommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>

                    {/************ Commission History ************/}
                    <Menu.Item key="areabusinesslinemanagerCommissionListHistory">
                      <Link to="/areabusinesslinemanager-commissionlisthistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionListHistory" />
                      </Link>
                    </Menu.Item>
                    {/************ Awaiting Higher Approvals ************/}
                    <Menu.Item key="areabusinesslinemanagerawatingcommisssionexception">
                      <Link to="/areabusinesslinemanager-awaitingcommisssionexceptions">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="areabusinesslinemanagerOverAchievementExceptions">
                      <Link to="/va/areabusinesslinemanager-overachievementexception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementException" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="areabusinesslinemanagerOverAchievementExceptionsHistory">
                      <Link to="/va/areabusinesslinemanager-overachievementexceptionhistory">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementHistory" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                 Submenu for Rejected Sales from Approval
              *******************************************/}
                  <SubMenu
                    key="areabusinesslinemanagerrejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="dislike" style={{ fontSize: "20px" }} />
                        Rejections
                      </div>
                    }
                  >
                    {/************ Change Commission ************/}
                    <Menu.Item key="areabusinesslinemanagerRejectedCommissionList">
                      <Link to="/areabusinesslinemanager-rejectedcommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <Menu.Item key="areabusinesslinemanagerSales">
                    <Link to="/areabusinesslinemanager-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  {/* Report Submenu */}
                  <SubMenu
                    key="areabusinesslinemanagerreport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    <Menu.Item key="areabusinesslinemanagerCommissionReport">
                      <Link to="/areabusinesslinemanager-changecommissionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Correction Report************/}
                    <Menu.Item key="areabusinesslinemanagerCorrectionreport">
                      <Link to="/areabusinesslinemanager-correctionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areabusinesslinemanagerTargetDistributorReport">
                      <Link to="/areabusinesslinemanager-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areabusinesslinemanagerPayoutStatusReport">
                      <Link to="/areabusinesslinemanager-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="areabusinesslinemanagerIncentiveSimulator">
                    <Link to="/areabusinesslinemanager-incentivesimulator">
                      <Icon type="percentage" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.incentiveSimulator" />
                    </Link>
                  </Menu.Item>
                  {/* DATA ANALYTICS */}]{" "}
                  <Menu.Item key="areabusinesslinemanagerdataanalytics">
                    <Link to="/areabusinesslinemanager-dataanalytics">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dataanalytics" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areabusinesslinemanagerdashboard">
                    <Link to="/va/areabusinesslinemanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* user summary */}
                  <Menu.Item key="areabusinesslinemanagersummary">
                    <Link to="/va/areabusinesslinemanager-usersummary">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.usersummary" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="areabusinesslinemanagerlisttarget">
                    <Link to="/va/areabusinesslinemanager-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>

                  {/* <Menu.Item key="areabusinesslinemanagerforecast">
                    <Link to="/va/areabusinesslinemanager-forecast">
                      <Icon type="fund" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.forecast" />
                    </Link>
                  </Menu.Item> */}

                  {/*exceptions  */}
                  <SubMenu
                    key="areabusinesslinemanagerexceptions"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Exception
                      </div>
                    }
                  >
                    {/* change commission*/}
                    <Menu.Item key="areabusinesslinemanagerchangecommission">
                      <Link to="/va/areabusinesslinemanager-changecommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.payoutexception" />
                      </Link>
                    </Menu.Item>

                    {/* collectionn commission */}
                    <Menu.Item key="areabusinesslinemanagercollectioncommission">
                      <Link to="/va/areabusinesslinemanager-collectioncommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.tr120Incentive" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="pendingapprovalsmd"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ Over Achievement Exceptions ************/}
                    <Menu.Item key="mdOverAchievementExceptions">
                      <Link to="/va/areabusinesslinemanager-overachievementexception">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementException" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="mdOverAchievementExceptions">
                      <Link to="/va/areabusinesslinemanager-overachievementexceptionhistory">
                        <Icon type="exception" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.overAchievementHistory" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* Reports */}
                  <SubMenu
                    key="areabusinesslinemanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="areabusinesslinemanagerCommissionDetailedReport">
                      <Link to="/va/areabusinesslinemanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="areabusinesslinemanagercommissionsummaryreport">
                      <Link to="/va/areabusinesslinemanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areabusinesslinemanagerTargetDistributorReport">
                      <Link to="/va/areabusinesslinemanager-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areabusinesslinemanagerPayoutStatusReport">
                      <Link to="/va/areabusinesslinemanager-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /****************************************************
             * else if role id is Area Payroll Manager; i.e., 5
             ****************************************************/
            role_id === 5 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areapayrollmanagerdashboard">
                    <Link to="/areapayrollmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="areapayrollmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="areapayrollmanagerpendingpayouts">
                      <Link to="/areapayrollmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="areapayrollmanagerapprovedpayouts">
                      <Link to="/areapayrollmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  {/* Sales */}
                  <Menu.Item key="areapayrollmanagerSales">
                    <Link to="/areapayrollmanager-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  {/*Report*/}
                  <Menu.Item key="areahrdirectorReport">
                    <Link to="/report">
                      <Icon type="profile" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.report" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areapayrollmanagerdashboard">
                    <Link to="/va/areapayrollmanagerdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="vaareapayrollmanagerpayouts"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Payouts
                      </div>
                    }
                  >
                    {/* Pending Payouts */}
                    <Menu.Item key="areapayrollmanagerpendingpayouts">
                      <Link to="/va/areapayrollmanager-pendingpayouts">
                        <Icon type="pay-circle" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpendingpayouts" />
                      </Link>
                    </Menu.Item>

                    {/* Approved Payouts */}
                    <Menu.Item key="areapayrollmanagerapprovedpayouts">
                      <Link to="/va/areapayrollmanager-approvedpayouts">
                        <Icon
                          type="check-square"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.listapprovedpayouts" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Payout Ends */}
                  {/* Reports */}
                  <SubMenu
                    key="areapayrollmanagerCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="areapayrollmanagerCommissionDetailedReport">
                      <Link to="/va/areapayrollmanager-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="areapayrollmanagercommissionsummaryreport">
                      <Link to="/va/areapayrollmanager-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                  {/* */}
                </Menu>
              )
            ) : /****************************************************
             *      else if role id is Area CBM; i.e., 4
             ****************************************************/
            role_id === 4 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areacbmdashboard">
                    <Link to="/areacbmdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Payout Settings */}
                  <Menu.Item key="areacbmpayoutsettings">
                    <Link to="/listpayout">
                      <Icon type="build" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.listpayout" />
                    </Link>
                  </Menu.Item>
                  {/* INCENTIVES SETTINGS */}
                  <Menu.Item key="areacbmincentivessettings">
                    <Link to="/listincentives">
                      <Icon type="build" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.listincentives" />
                    </Link>
                  </Menu.Item>
                  {/* Sales */}
                  <Menu.Item key="areacbmSales">
                    <Link to="/areacbm-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  {/*Report*/}

                  <SubMenu
                    key="areaCBMReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    <Menu.Item key="areacbmCommissionReport">
                      <Link to="/areacbm-changecommissionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Correction Report************/}
                    <Menu.Item key="areacbmCorrectionreport">
                      <Link to="/areacbm-correctionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areacbmTargetDistributorReport">
                      <Link to="/areacbm-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areacbmPayoutStatusReport">
                      <Link to="/areacbm-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areacbmPayoutReport">
                      <Link to="/report">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areacbmdashboard">
                    <Link to="/va/areacbmdashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/*Report*/}

                  <SubMenu
                    key="areaCBMReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areacbmTargetDistributorReport">
                      <Link to="/va/areacbm-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areacbmPayoutStatusReport">
                      <Link to="/va/areacbm-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areacbmPayoutReport">
                      <Link to="/va/areacbm-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /****************************************************
             *      else if role id is Area HR Director; i.e., 3
             ****************************************************/
            role_id === 3 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areahrdirectordashboard">
                    <Link to="/areahrdirectordashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="adminsettings"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-setting" />
                        <span>Admin Settings</span>
                      </span>
                    }
                  >
                    {/* Submenu for Geographical Level */}
                    <SubMenu
                      key="geographicallevel"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      title={
                        <span>
                          <i className="icon icon-select" />
                          <span>Geographical Level</span>
                        </span>
                      }
                    >
                      <Menu.Item key="managearea">
                        <Link to="/listareas">
                          <Icon type="global" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.managearea" />
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="managefrontline">
                        <Link to="/listfrontlines">
                          <Icon type="apartment" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.managefrontline" />
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="manageregion">
                        <Link to="/listregions">
                          <Icon type="appstore" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.manageregion" />
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="managebranches">
                        <Link to="/listbranches">
                          <Icon
                            type="deployment-unit"
                            style={{ fontSize: "14px" }}
                          />
                          <IntlMessages id="sidebar.managebranches" />
                        </Link>
                      </Menu.Item>
                    </SubMenu>{" "}
                    {/* Geographical Level Ends */}
                    {/* Submenu for Project Types */}
                    <SubMenu
                      key="projecttypes"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      title={
                        <span>
                          <i className="icon icon-menu" />
                          <span>Project Types</span>
                        </span>
                      }
                    >
                      <Menu.Item key="businessline">
                        {" "}
                        {/* Business Line */}
                        <Link to="/listbusinessline">
                          <Icon type="gateway" style={{ fontSize: "16px" }} />
                          <IntlMessages id="sidebar.listbusinessline" />
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="projecttype">
                        {" "}
                        {/* Project Type */}
                        <Link to="/listprojecttype">
                          <Icon type="project" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.listprojecttype" />
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="subprojecttype">
                        {" "}
                        {/* Sub Project Type */}
                        <Link to="/listsubprojecttype">
                          <Icon type="project" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.listsubprojecttype" />
                        </Link>
                      </Menu.Item>
                    </SubMenu>{" "}
                    {/* Project Types Ends */}
                    <Menu.Item key="nsskicker">
                      {" "}
                      {/* NSS Kicker */}
                      <Link to="/nsskicker">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nsskicker" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="currencies">
                      {" "}
                      {/* Currencies - Currency Settings */}
                      <Link to="/listcurrencies">
                        <Icon type="euro" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listcurrencies" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="payoutsettings">
                      {" "}
                      {/* Payout List */}
                      <Link to="/listpayout">
                        <Icon type="build" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpayout" />
                      </Link>
                    </Menu.Item>
                    {/* INCENTIVES SETTINGS */}
                    <Menu.Item key="incentivessettings">
                      {" "}
                      {/* Target List */}
                      <Link to="/listincentives">
                        <Icon type="build" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listincentives" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Admin Settings SUBMENU Ends */}
                  <Menu.Item key="users">
                    <Link to="/listusers">
                      <Icon type="team" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.users" />
                    </Link>
                  </Menu.Item>
                  {/*SAP Order Upload*/}
                  <Menu.Item key="saporderupload">
                    <Link to="/saporderupload">
                      <Icon type="upload" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.saporderupload" />
                    </Link>
                  </Menu.Item>
                  {/* DATA ANALYTICS */}
                  <Menu.Item key="dataanalytics">
                    <Link to="/areahrdirector-dataanalytics">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dataanalytics" />
                    </Link>
                  </Menu.Item>
                  {/* Report Submenu */}
                  <SubMenu
                    key="areahrdirectorReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    <Menu.Item key="areahrdirectorCommissionReport">
                      <Link to="/areahrdirector-changecommissionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Correction Report************/}
                    <Menu.Item key="areahrdirectorCorrectionreport">
                      <Link to="/areahrdirector-correctionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areahrdirectorTargetDistributorReport">
                      <Link to="/areahrdirector-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areahrdirectorPayoutStatusReport">
                      <Link to="/areahrdirector-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areahrdirectorPayoutReport">
                      <Link to="/report">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/*******************************
                 Submenu for Pending Approvals 
              *******************************/}
                  <SubMenu
                    key="areahrdirectorpendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ Change Commission ************/}
                    <Menu.Item key="areahrdirectorChangeCommissionList">
                      <Link to="/areahrdirector-changecommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>

                    {/************ Commission History ************/}
                    <Menu.Item key="areahrdirectorCommissionListHistory">
                      <Link to="/areahrdirector-commissionlisthistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionListHistory" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="areahrdirectorawatingcommisssionexception">
                      <Link to="/areahrdirector-awaitingcommisssionexceptions">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.salesmanagerlistnsskickerawaitingapprovals" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                 Submenu for Rejected Sales from Approval
              *******************************************/}
                  <SubMenu
                    key="areahrdirectorrejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="dislike" style={{ fontSize: "20px" }} />
                        Rejections
                      </div>
                    }
                  >
                    {/************ Change Commission ************/}
                    <Menu.Item key="areahrdirectorRejectedCommissionList">
                      <Link to="/areahrdirector-rejectedcommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  <SubMenu
                    key="salesmenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Orders</span>
                      </span>
                    }
                  >
                    <Menu.Item key="actualsales">
                      <Link to="/actualsales">
                        <Icon type="line-chart" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.actualorder" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="duplicatesales">
                      <Link to="/duplicatesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.duplicatesales" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="incompletesales">
                      <Link to="/incompletesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.incompletesales" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="exception">
                      <Link to="/exception">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.exception" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Sales Ends */}
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areahrdirectordashboard">
                    <Link to="/va/areahrdirectordashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  <SubMenu
                    key="areahrdirectorexceptions"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Exception
                      </div>
                    }
                  >
                    {/* change commission*/}
                    <Menu.Item key="areahrdirectorchangecommission">
                      <Link to="/va/areahrdirector-changecommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.payoutexception" />
                      </Link>
                    </Menu.Item>

                    {/* collectionn commission */}
                    <Menu.Item key="areahrdirectorcollectioncommission">
                      <Link to="/va/areahrdirector-collectioncommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.tr120Incentive" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* Reports */}
                  <SubMenu
                    key="areahrdirectorCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="areahrdirectorCommissionDetailedReport">
                      <Link to="/va/areahrdirector-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="areahrdirectorcommissionsummaryreport">
                      <Link to="/va/areahrdirector-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areahrdirectorTargetDistributorReport">
                      <Link to="/va/areahrdirector-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areahrdirectorPayoutStatusReport">
                      <Link to="/va/areahrdirector-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areahrdirectorPayoutReport">
                      <Link to="/va/areahrdirector-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*************************************************************
             *      else if role id is Area MD; i.e., 2
             **************************************************************/
            role_id === 2 ? (
              businessLineDetails.default !== "VA" ? (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areamddashboard">
                    <Link to="/areamddashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="areamdlisttarget">
                    <Link to="/areamd-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/*******************************
                  Submenu for Pending Approvals 
                *******************************/}
                  <SubMenu
                    key="areamdpendingapprovals"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="like" style={{ fontSize: "20px" }} />
                        Approvals
                      </div>
                    }
                  >
                    {/************ NSS Kicker ************/}
                    {/* <Menu.Item key="mdnsskickerpendingapprovals">    
                    <Link to="/md-listnsskickerpendingapprovals"><Icon type="control" style={{fontSize:'16px'}} />
                      <IntlMessages id="sidebar.mdlistnsskickerpendingapprovals"/></Link>
                  </Menu.Item> */}

                    {/************ Change Commission ************/}
                    <Menu.Item key="areamdChangeCommissionList">
                      <Link to="/areamd-changecommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>

                    {/************ Commission History ************/}
                    <Menu.Item key="areamdCommissionListHistory">
                      <Link to="/areamd-commissionlisthistory">
                        <Icon type="history" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionListHistory" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Pending Approvals Ends */}
                  {/*******************************************
                  Submenu for Rejected Sales from Approval
                *******************************************/}
                  <SubMenu
                    key="areamdrejectedsales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="dislike" style={{ fontSize: "20px" }} />
                        Rejections
                      </div>
                    }
                  >
                    {/************ Change Commission ************/}
                    <Menu.Item key="areamdRejectedCommissionList">
                      <Link to="/areamd-rejectedcommissionlist">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.commissionList" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Rejected Sales Ends */}
                  {/* Report  */}
                  <SubMenu
                    key="areamdreport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    <Menu.Item key="areamdCommissionReport">
                      <Link to="/areamd-changecommissionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Correction Report************/}
                    <Menu.Item key="areamdCorrectionreport">
                      <Link to="/areamd-correctionreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.correctionReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areamdTargetDistributorReport">
                      <Link to="/areamd-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areamdPayoutStatusReport">
                      <Link to="/areamd-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areamdPayoutReport">
                      <Link to="/report">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="areamdSales">
                    <Link to="/areamd-sales">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.salesmanMyorder" />
                    </Link>
                  </Menu.Item>
                  {/********************************
                  Submenu for Incentive Simulator
                **********************************/}
                  <SubMenu
                    key="areamdincentivesimulators"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="calculator" style={{ fontSize: "20px" }} />
                        Simulators
                      </div>
                    }
                  >
                    <Menu.Item key="areamdBranchSimulator">
                      <Link to="/areamd-branchsimulator">
                        <Icon type="branches" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.branchSimulator" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="areamdIncentiveSimulator">
                      <Link to="/areamd-incentivesimulator">
                        <Icon type="percentage" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.incentiveSimulator" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              ) : (
                <Menu
                  onOpenChange={this.onOpenChange}
                  theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                  mode="inline"
                  onClick={this.handleClick}
                  selectedKeys={[this.state.current]}
                >
                  <Menu.Item key="areamddashboard">
                    <Link to="/va/areamddashboard">
                      <Icon type="dashboard" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dashboard" />
                    </Link>
                  </Menu.Item>
                  {/* Target Settings */}
                  <Menu.Item key="areamdlisttarget">
                    <Link to="/va/areamd-listtarget">
                      <Icon type="radar-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.listtarget" />
                    </Link>
                  </Menu.Item>
                  {/* Exceptions */}
                  <SubMenu
                    key="areamddirectorexceptions"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="interaction" style={{ fontSize: "20px" }} />
                        Exception
                      </div>
                    }
                  >
                    {/* change commission*/}
                    <Menu.Item key="areamddirectorchangecommission">
                      <Link to="/va/areamd-changecommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.payoutexception" />
                      </Link>
                    </Menu.Item>

                    {/* collectionn commission */}
                    <Menu.Item key="areamddirectorcollectioncommission">
                      <Link to="/va/areamd-collectioncommission">
                        <Icon
                          type="money-collect"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.tr120Incentive" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  {/* Reports */}
                  <SubMenu
                    key="areamdCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="areamdCommissionDetailedReport">
                      <Link to="/va/areamd-commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="areamdcommissionsummaryreport">
                      <Link to="/va/areamd-commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="areamdTargetDistributorReport">
                      <Link to="/va/areamd-targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="areamdPayoutStatusReport">
                      <Link to="/va/areamd-payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="areamdPayoutReport">
                      <Link to="/va/areamd-payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                  <Menu.Item key="tickets">
                    <Link to="/tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                </Menu>
              )
            ) : /*********************************************************
             *      else if role id is BO Shared Service Center; i.e., 13
             *********************************************************/
            role_id === 13 ? (
              <Menu
                //defaultOpenKeys={[defaultOpenKeys]}
                //selectedKeys={[selectedKeys]}
                //openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                {/*SAP Order Upload*/}
                <Menu.Item key="saporderupload">
                  <Link to="/saporderupload">
                    <Icon type="upload" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.saporderupload" />
                  </Link>
                </Menu.Item>
                {/*Report*/}
                <Menu.Item key="boReport">
                  <Link to="/report">
                    <Icon type="profile" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.report" />
                  </Link>
                </Menu.Item>
                <SubMenu
                  key="salesmenu"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-menu" />
                      <span>Orders</span>
                    </span>
                  }
                >
                  <Menu.Item key="actualsales">
                    <Link to="/actualsales">
                      <Icon type="line-chart" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.actualorder" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="duplicatesales">
                    <Link to="/duplicatesales">
                      <Icon type="filter" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.duplicatesales" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="incompletesales">
                    <Link to="/incompletesales">
                      <Icon type="filter" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.incompletesales" />
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="exception">
                    <Link to="/exception">
                      <Icon type="filter" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.exception" />
                    </Link>
                  </Menu.Item>
                </SubMenu>
                {/* Sales Ends */}
                {/* Targets */}
                <SubMenu
                  key="targetsmenu"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-chart-radar" />
                      <span>Targets</span>
                    </span>
                  }
                >
                  <Menu.Item key="unlockrequests">
                    <Link to="/unlockrequests">
                      <Icon type="unlock" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.unlockrequests" />
                    </Link>
                  </Menu.Item>

                  <Menu.Item key="globallockunlock">
                    <Link to="/globallockunlock">
                      <Icon type="unlock" style={{ fontSize: "14px" }} />
                      <IntlMessages id="sidebar.globallockunlock" />
                    </Link>
                  </Menu.Item>
                </SubMenu>{" "}
                {/* Targets Ends */}
              </Menu>
            ) : /*********************************************************
             *      else if role id is Admin; i.e., 1
             *********************************************************/
            role_id === 1 ? (
              <Menu
                //defaultOpenKeys={[defaultOpenKeys]}
                //selectedKeys={[selectedKeys]}
                //openKeys={this.state.openKeys}
                onOpenChange={this.onOpenChange}
                theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
                mode="inline"
                onClick={this.handleClick}
                selectedKeys={[this.state.current]}
              >
                {/*
                <Menu.Item key="dashboard">
                  <Link to="/dashboard"><Icon type="dashboard" style={{fontSize:'20px'}} />
                    <IntlMessages id="sidebar.dashboard"/></Link>
                </Menu.Item>
                */}
                <SubMenu
                  key="adminsettings"
                  className={this.getNavStyleSubMenuClass(navStyle)}
                  title={
                    <span>
                      <i className="icon icon-setting" />
                      <span>Admin Settings</span>
                    </span>
                  }
                >
                  {/* Submenu for Geographical Level */}
                  <SubMenu
                    key="geographicallevel"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-select" />
                        <span>Geographical Level</span>
                      </span>
                    }
                  >
                    <Menu.Item key="managearea">
                      <Link to="/listareas">
                        <Icon type="global" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.managearea" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="managefrontline">
                      <Link to="/listfrontlines">
                        <Icon type="apartment" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.managefrontline" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="manageregion">
                      <Link to="/listregions">
                        <Icon type="appstore" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.manageregion" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="managebranches">
                      <Link to="/listbranches">
                        <Icon
                          type="deployment-unit"
                          style={{ fontSize: "14px" }}
                        />
                        <IntlMessages id="sidebar.managebranches" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>{" "}
                  {/* Geographical Level Ends */}
                  {/* Submenu for Project Types */}
                  <SubMenu
                    key="projecttypes"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Project Types</span>
                      </span>
                    }
                  >
                    <Menu.Item key="businessline">
                      {" "}
                      {/* Business Line */}
                      <Link to="/listbusinessline">
                        <Icon type="gateway" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listbusinessline" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="projecttype">
                      {" "}
                      {/* Project Type */}
                      <Link to="/listprojecttype">
                        <Icon type="project" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.listprojecttype" />
                      </Link>
                    </Menu.Item>
                    {businessLineDetails.default !== "VA" ? (
                      <Menu.Item key="subprojecttype">
                        {" "}
                        {/* Sub Project Type */}
                        <Link to="/listsubprojecttype">
                          <Icon type="project" style={{ fontSize: "14px" }} />
                          <IntlMessages id="sidebar.listsubprojecttype" />
                        </Link>
                      </Menu.Item>
                    ) : null}
                  </SubMenu>{" "}
                  {/* Project Types Ends */}
                  {businessLineDetails.default !== "VA" ? (
                    <Menu.Item key="nsskicker">
                      {" "}
                      {/* NSS Kicker */}
                      <Link to="/nsskicker">
                        <Icon type="control" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.nsskicker" />
                      </Link>
                    </Menu.Item>
                  ) : null}
                  <Menu.Item key="currencies">
                    {" "}
                    {/* Currencies - Currency Settings */}
                    <Link to="/listcurrencies">
                      <Icon type="euro" style={{ fontSize: "16px" }} />
                      <IntlMessages id="sidebar.listcurrencies" />
                    </Link>
                  </Menu.Item>
                  {businessLineDetails.default === "VA" ? (
                    <SubMenu
                      key="adminVASettings"
                      className={this.getNavStyleSubMenuClass(navStyle)}
                      title={
                        <span>
                          <i className="icon icon-menu" />
                          <span>
                            <IntlMessages id="sidebar.vasettings" />
                          </span>
                        </span>
                      }
                    >
                      <Menu.Item key="incentivesettings">
                        <Link to="/vaincentivesettings">
                          <Icon type="dot-chart" style={{ fontSize: "16px" }} />
                          <IntlMessages id="sidebar.listincentives" />
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="payoutsettings">
                        <Link to="/vapayoutsettings">
                          <Icon type="build" style={{ fontSize: "16px" }} />
                          <IntlMessages id="sidebar.listpayout" />
                        </Link>
                      </Menu.Item>
                      <Menu.Item key="tr120settings">
                        <Link to="/va/tr120settings">
                          <Icon type="build" style={{ fontSize: "16px" }} />
                          <IntlMessages id="sidebar.tr120settings" />
                        </Link>
                      </Menu.Item>

                      <Menu.Item key="adminVASettings">
                        <Link to="/vasettings">
                          <Icon type="setting" style={{ fontSize: "20px" }} />
                          <IntlMessages id="sidebar.targetgapforupperlimit" />
                        </Link>
                      </Menu.Item>
                    </SubMenu>
                  ) : null}
                  {businessLineDetails.default !== "VA" ? (
                    <Menu.Item key="payoutsettings">
                      {" "}
                      {/* Payout List */}
                      <Link to="/listpayout">
                        <Icon type="build" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listpayout" />
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {
                                                  (businessLineDetails.default !== 'VA') ?
                                                    <Menu.Item key="incentivesbucketsettings">   
                                                      <Link to="/listincentivesbucket"><Icon type="build" style={{ fontSize: '16px' }} />
                                                        <IntlMessages id="sidebar.listincentivesbucket" /></Link>
                                                    </Menu.Item>
                                                    :
                                                    null
                                                } */}
                  {businessLineDetails.default !== "VA" ? (
                    /* INCENTIVES SETTINGS */
                    <Menu.Item key="incentivessettings">
                      <Link to="/listincentives">
                        <Icon type="build" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.listincentives" />
                      </Link>
                    </Menu.Item>
                  ) : null}
                  {/* {
                                            (businessLineDetails.default !== 'VA') ?
                                              <Menu.Item key="bravoincentivesettings">
                                                <Link to="/listbravoincentives"><Icon type="build" style={{ fontSize: '16px' }} />
                                                  <IntlMessages id="sidebar.bravoIncentiveSettings" /></Link>
                                              </Menu.Item>
                                              :
                                              null
                                          } */}
                  {businessLineDetails.default !== "VA" ? (
                    <Menu.Item key="sendnotification">
                      <Link to="/sendnotification">
                        <Icon
                          type="notification"
                          style={{ fontSize: "16px" }}
                        />
                        <IntlMessages id="sidebar.sendnotification" />
                      </Link>
                    </Menu.Item>
                  ) : null}
                </SubMenu>{" "}
                {/* Admin Settings SUBMENU Ends */}
                {businessLineDetails.default === "VA" ? (
                  /* Submenu for Manage KPI */
                  <SubMenu
                    key="manageVASales"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <Icon
                          type="like"
                          style={{
                            fontSize: "20px",
                            position: "relative",
                            top: "10px"
                          }}
                        />
                        <span>
                          <IntlMessages id="sidebar.pendingapprovals" />
                        </span>
                      </span>
                    }
                  >
                    <Menu.Item key="adminListVASales">
                      <Link to="/listvasales">
                        <Icon type="line-chart" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.vasales" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {/* {

                                    (businessLineDetails.default === 'VA') ?
                                      <Menu.Item key="contractupload">
                                        <Link to="/contractupload"><Icon type="upload" style={{ fontSize: '20px' }} />
                                          <IntlMessages id="sidebar.contractupload" /></Link>
                                      </Menu.Item>

                                      :
                                      null
                                       } */}
                <Menu.Item key="auditlog">
                  <Link to="/auditlog">
                    <Icon type="audit" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.auditlog" />
                  </Link>
                </Menu.Item>
                <Menu.Item key="users">
                  <Link to="/listusers">
                    <Icon type="team" style={{ fontSize: "20px" }} />
                    <IntlMessages id="sidebar.users" />
                  </Link>
                </Menu.Item>
                {businessLineDetails.default === "VA" ? (
                  // <SubMenu
                  //       key="targetsettings"
                  //       className={this.getNavStyleSubMenuClass(navStyle)}
                  //       title={
                  //         <span><i className="icon icon-menu" /><span>Target Settings</span></span>
                  //       }
                  //     >
                  //       <Menu.Item key="targetuploading">
                  //         <Link to="/targetupload"><Icon type="upload" style={{ fontSize: '14px' }} />
                  //           <IntlMessages id="sidebar.targetUpload" />
                  //         </Link>
                  //       </Menu.Item>
                  //       <Menu.Item key="actualupload">
                  //         <Link to="/actualupload"><Icon type="upload" style={{ fontSize: '14px' }} />
                  //           <IntlMessages id="sidebar.actualUpload" />
                  //         </Link>
                  //       </Menu.Item>
                  //       <Menu.Item key="templatedownloading">
                  //         <Link to="/templatedownload"><Icon type="download" style={{ fontSize: '14px' }} />
                  //           <IntlMessages id="sidebar.templateDownload" />
                  //         </Link>
                  //       </Menu.Item>
                  // <Menu.Item key="financial">
                  //   <Link to="/financialupload"><Icon type="upload" style={{ fontSize: '20px' }} />
                  //     <IntlMessages id="sidebar.financialupload" /></Link>
                  // </Menu.Item>


                  // <SubMenu
                  //   key="financialdata"
                  //   className={this.getNavStyleSubMenuClass(navStyle)}
                  //   title={
                  //     <div>
                  //       <Icon type="profile" style={{ fontSize: "20px" }} />
                  //       Financial Data
                  //     </div>
                  //   }
                  // >
                  //   {/************ Financial Data ************/}
                  //   <Menu.Item key="upload">
                  //     <Link to="/financialupload">
                  //       <Icon type="upload" style={{ fontSize: "20px" }} />
                  //       <IntlMessages id="sidebar.dataEntry.upload" />
                  //     </Link>
                  //   </Menu.Item>
                  //   <Menu.Item key="list">
                  //     <Link to="/financiallist">
                  //       <Icon type="profile" style={{ fontSize: "20px" }} />
                  //       <IntlMessages id="sidebar.dataDisplay.list" />
                  //     </Link>
                  //   </Menu.Item>
                  //   <Menu.Item key="uploadhistory">
                  //     <Link to="/financialuploadhistory">
                  //       <Icon type="history" style={{ fontSize: "20px" }} />
                  //       <IntlMessages id="sidebar.uploadHistory" />
                  //     </Link>
                  //   </Menu.Item>
                  // </SubMenu>

                  ""
                ) : (
                  ""
                )}

                {/* Limbo upload */}

                {businessLineDetails.default === "VA" ? (
                <SubMenu
                    key="limbouploads"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="table" style={{ fontSize: "20px" }} />
                        LIMBO Uploads
                      </div>
                    }
                  >
                    <Menu.Item key="limbo-arageing">
                      <Link to="limbo-arageing">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.arageing" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="limbo-vprp">
                      <Link to="limbo-vprp">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.vprp" />
                      </Link>
                    </Menu.Item>
                     <Menu.Item key="limbo-zi11">
                         <Link to="/limbo-zi11"><Icon type="upload" style={{ fontSize: '20px' }} />
                          <IntlMessages id="sidebar.zi11" /></Link>
                     </Menu.Item>
                     <Menu.Item key="limbo-zvc">
                        <Link to="/limbo-zvc"><Icon type="upload" style={{ fontSize: '20px' }} />
                        <IntlMessages id="sidebar.zvc" /></Link>
                   </Menu.Item> 
                  </SubMenu> 
                )
              : ""}

                {businessLineDetails.default !== "VA" ? (
                  <SubMenu
                    key="financialdata"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="profile" style={{ fontSize: "20px" }} />
                        Data upload
                      </div>
                    }
                  >
                    {/* SAP order upload */}
                    <Menu.Item key="qlicksenseupload">
                      <Link to="/qlicksenseupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.qlicksenseupload" />
                      </Link>
                    </Menu.Item>

                    {/* Qlick sense upload */}

                    <Menu.Item key="saporderupload">
                      <Link to="/saporderupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.saporderupload" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="crmdataupload">
                      <Link to="/crmdataupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.crmdataupload" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : (
                  <SubMenu
                    key="dataupload"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="profile" style={{ fontSize: "20px" }} />
                        Data upload
                      </div>
                    }
                  >
                    {/* Contract order upload */}
                    <Menu.Item key="contractupload">
                      <Link to="/contractupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.contractupload" />
                      </Link>
                    </Menu.Item>

                    {/* Forecast upload */}
                    {/* <Menu.Item key="forecastupload">
                      <Link to="/forecastupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="Forecast Upload" />
                      </Link>
                    </Menu.Item> */}

                    {/* Qlick sense upload */}

                    <Menu.Item key="keyaccountsupload">
                      <Link to="/keyaccountsupload">
                        <Icon type="upload" style={{ fontSize: "20px" }} />
                        <IntlMessages id="sidebar.keyaccountsupload" />
                      </Link>
                    </Menu.Item>
                    
                  </SubMenu>
                )}
                {/* Report Submenu */}
                {businessLineDetails.default !== "VA" ? (
                  <SubMenu
                    key="adminReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      // <div><Icon type="profile" style={{ fontSize: '20px' }} />Report</div>
                      <span>
                        <i className="icon icon-menu" />
                        <span>Report</span>
                      </span>
                    }
                  >
                    {/************ Change Commission Report ************/}
                    {/* <Menu.Item key="adminCommissionReport">
                                                      <Link to="/changecommissionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                                        <IntlMessages id="sidebar.commissionReport" /></Link>
                                                    </Menu.Item> */}

                    {/************ Correction Report************/}
                    {/* <Menu.Item key="adminCorrectionreport">
                                                      <Link to="/correctionreport"><Icon type="profile" style={{ fontSize: '16px' }} />
                                                        <IntlMessages id="sidebar.correctionReport" /></Link>
                                                    </Menu.Item> */}
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="adminTargetDistributorReport">
                      <Link to="/targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="adminPayoutStatusReport">
                      <Link to="/payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="PayoutReport">
                      <Link to="/report">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {businessLineDetails.default !== "VA" ? (
                  /* Payouts */
                  <Menu.Item key="adminPayouts">
                    <Link to="/payouts">
                      <Icon
                        type="unordered-list"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.payouts" />
                    </Link>
                  </Menu.Item>
                ) : null}
                {/* Sales Submenu */}
                {businessLineDetails.default !== "VA" ? (
                  <SubMenu
                    key="salesmenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Orders</span>
                      </span>
                    }
                  >
                    <Menu.Item key="actualsales">
                      <Link to="/actualsales">
                        <Icon type="line-chart" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.actualorder" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="duplicatesales">
                      <Link to="/duplicatesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.duplicatesales" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="incompletesales">
                      <Link to="/incompletesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.incompletesales" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="exception">
                      <Link to="/exception">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.exception" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {businessLineDetails.default !== "VA" ? (
                  <Menu.Item key="admindataanalytics">
                    <Link to="/dataanalytics">
                      <Icon type="line-chart" style={{ fontSize: "20px" }} />
                      <IntlMessages id="sidebar.dataanalytics" />
                    </Link>
                  </Menu.Item>
                ) : null}
                {/* Qlick Sense Sales Submenu */}
                {businessLineDetails.default !== "VA" ? (
                  <SubMenu
                    key="qlicksalesmenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-menu" />
                        <span>Qlick Sense Sales</span>
                      </span>
                    }
                  >
                    <Menu.Item key="qlicksensesales">
                      <Link to="/qlicksensesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksensesalesready" />
                      </Link>
                    </Menu.Item>
                    <Menu.Item key="qlicksenseduplicatesales">
                      <Link to="/qlicksenseduplicatesales">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksenseduplicatesales" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="qlicksenseexception">
                      <Link to="/qlicksenseexception">
                        <Icon type="filter" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.qlicksenseexception" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {businessLineDetails.default === "VA" ? (
                  <SubMenu
                    key="adminCommissionDetailedReport"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <div>
                        <Icon type="project" style={{ fontSize: "20px" }} />
                        Report
                      </div>
                    }
                  >
                    {/*Commission Detailed Reports */}
                    <Menu.Item key="adminCommissionDetailedReport">
                      <Link to="/va/commissiondetailedreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionDetailedReport" />
                      </Link>
                    </Menu.Item>
                    {/*Commission Summary  Reports */}
                    <Menu.Item key="admincommissionsummaryreport">
                      <Link to="/va/commissionsummaryreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.commissionSummaryReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Target Distributor Report************/}
                    <Menu.Item key="adminTargetDistributorReport">
                      <Link to="/va/targetdistributorreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.targetDistributorReport" />
                      </Link>
                    </Menu.Item>
                    {/************ Payout Status Report************/}
                    <Menu.Item key="adminPayoutStatusReport">
                      <Link to="/va/payoutstatusreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutStatusReport" />
                      </Link>
                    </Menu.Item>

                    {/************ Payout Report************/}
                    <Menu.Item key="adminPayoutReport">
                      <Link to="/va/payoutreport">
                        <Icon type="profile" style={{ fontSize: "16px" }} />
                        <IntlMessages id="sidebar.payoutReport" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {businessLineDetails.default !== "VA" ? (
                  /* Targets */
                  <SubMenu
                    key="targetsmenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-chart-radar" />
                        <span>Targets</span>
                      </span>
                    }
                  >
                    <Menu.Item key="unlockrequests">
                      <Link to="/unlockrequests">
                        <Icon type="unlock" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.unlockrequests" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="globallockunlock">
                      <Link to="/globallockunlock">
                        <Icon type="unlock" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.globallockunlock" />
                      </Link>
                    </Menu.Item>
                  </SubMenu> /* Targets Ends */
                ) : null}
                {/*Targets VA  */}
                {businessLineDetails.default === "VA" ? (
                  <SubMenu
                    key="targetsvamenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-chart-radar" />
                        <span>Targets</span>
                      </span>
                    }
                  >
                    <Menu.Item key="unlockvarequests">
                      <Link to="/va/unlockrequests">
                        <Icon type="unlock" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.unlockrequests" />
                      </Link>
                    </Menu.Item>

                    <Menu.Item key="globallockvaunlock">
                      <Link to="/va/globallockunlock">
                        <Icon type="unlock" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.globallockunlock" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {/* Performance */}
                {/*Targets VA  */}
                {businessLineDetails.default === "VA" ? (
                  <SubMenu
                    key="performancevamenu"
                    className={this.getNavStyleSubMenuClass(navStyle)}
                    title={
                      <span>
                        <i className="icon icon-chart-radar" />
                        <span>Performance</span>
                      </span>
                    }
                  >
                    <Menu.Item key="unlockvarequests">
                      <Link to="/va/performance/unlockrequests">
                        <Icon type="unlock" style={{ fontSize: "14px" }} />
                        <IntlMessages id="sidebar.unlockrequests" />
                      </Link>
                    </Menu.Item>
                  </SubMenu>
                ) : null}
                {
                  <Menu.Item key="tickets">
                    <Link to="/admin-tickets">
                      <Icon
                        type="customer-service"
                        style={{ fontSize: "20px" }}
                      />
                      <IntlMessages id="sidebar.tickets" />
                    </Link>
                  </Menu.Item>
                }
              </Menu>
            ) : (
              <div style={{ textAlign: "center" }}>Loading...</div>
            ) // else Loading...
            }
            {/* Menu Condition Ends */}
          </CustomScrollbars>
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, themeType, locale, pathname } = settings;
  const { authUser } = auth;
  const { businessLineDetails } = auth; // Fetching user business line - Added on July 27 2020
  return { navStyle, themeType, locale, pathname, authUser, businessLineDetails }
};
export default connect(mapStateToProps)(SidebarContent);

